@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Montserrat;
    font-weight: 400;
    src: url(assets/fonts/montserrat/Montserrat-Medium.ttf) format("ttf");
  },
  @font-face {
    font-family: Montserrat;
    font-weight: 500;
    src: url(assets/fonts/montserrat/Montserrat-Regular.ttf) format("ttf");
  }
}